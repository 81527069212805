<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">เพิ่มข้อมูลหัตถการ/คอร์สแบบ<span class="ml-3" style="text-decoration: underline;">{{createtypecourse}}</span></h4>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsCategory"
                v-model="operativecourse_category"
                item-text="name"
                item-value="value"
                label="หมวดหมู่"
                placeholder="เลือกหมวดหมู่"
                no-data-text="ยังไม่มีข้อมูล"
                outlined
                :rules="selectRules"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_code"
                label="รหัส"
                placeholder="ระบุรหัส"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อคอร์ส</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_name"
                label="ชื่อคอร์ส"
                placeholder="ระบุชื่อคอร์ส"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ราคา</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_costprice"
                label="ราคา"
                placeholder="ระบุราคา"
                suffix="บาท"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ค่ามือแพทย์ (ต่อครั้ง)</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_wagesdoctor"
                label="ค่ามือแพทย์ (ต่อครั้ง)"
                placeholder="ระบุค่ามือแพทย์ (ต่อครั้ง)"
                suffix="บาท"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ค่ามือพนักงาน (ต่อครั้ง)</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_wagesemployee"
                label="ค่ามือพนักงาน (ต่อครั้ง)"
                placeholder="ระบุค่ามือพนักงาน (ต่อครั้ง)"
                suffix="บาท"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row v-if="createtypecourse === 'จำกัดจำนวนครั้ง'" class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนครั้ง</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_amountcourse"
                label="จำนวนครั้ง"
                placeholder="ระบุจำนวนครั้ง"
                suffix="ครั้ง"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ระยะวันที่สิ้นสุด</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="operativecourse_amountday"
                label="ระยะวันที่สิ้นสุด"
                placeholder="ระบุระยะวันที่สิ้นสุด"
                suffix="วัน"
                outlined
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6"/>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-start">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">รายละเอียดอื่นๆ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-textarea
                outlined
                name="input-7-4"
                label="รายละเอียดอื่นๆ"
                placeholder="ระบุรายละเอียดอื่นๆ"
                :value="operativecourse_detail"
                :rules="inputRules"
                disabled
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="d-flex flex-row align-center justify-end mt-5">
        <v-btn
          color="success"
          @click="createNewList()"
          :loading="loaderSave"
          :disabled="loaderSave"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          บันทึก
        </v-btn>
        <v-btn
          color="error"
          class="ml-3"
          @click="goBack()"
          :loading="loaderCancel"
          :disabled="loaderCancel"
        >
          <v-icon class="mr-2">mdi-close-circle</v-icon>
          ยกเลิก
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
// import swal from 'sweetalert'

export default {
  data () {
    return {
      loaderSave: null,
      loaderCancel: null,
      createtypecourse: '',
      itemsCategory: [],
      operativecourse_category: '',
      operativecourse_code: '',
      operativecourse_name: '',
      operativecourse_costprice: 0,
      operativecourse_wagesdoctor: 0,
      operativecourse_wagesemployee: 0,
      operativecourse_amountcourse: 0,
      operativecourse_amountday: 0,
      operativecourse_detail: '',
      inputRules: [
        v => !!v || 'กรุณากรอกข้อมูล'
      ],
      selectRules: [
        v => !!v || 'กรุณาเลือกข้อมูล'
      ]
    }
  },
  created () {
    const self = this
    self.createtypecourse = self.$route.params.addType
  },
  methods: {
    goBack () {
      const self = this
      self.loaderSave = true
      self.loaderCancel = true

      setTimeout(() => {
        self.loaderSave = null
        self.loaderCancel = null
        self.$router.go(-1)
      }, 200)
    },
    createNewList () {
      const self = this
      self.loaderSave = true
      self.loaderCancel = true
      const objItems = {
        operativecourse_coursetype: self.createtypecourse,
        operativecourse_category: self.operativecourse_category,
        operativecourse_code: self.operativecourse_code,
        operativecourse_name: self.operativecourse_name,
        operativecourse_costprice: self.operativecourse_costprice,
        operativecourse_wagesdoctor: self.operativecourse_wagesdoctor,
        operativecourse_wagesemployee: self.operativecourse_wagesemployee,
        operativecourse_amountcourse: self.operativecourse_amountcourse,
        operativecourse_amountday: self.operativecourse_amountday,
        operativecourse_detail: self.operativecourse_detail
      }
      self.$router.push({ name: 'CreateAddDetailListOperativeAndCourseScreen', params: { createItem: objItems } })

      // if (self.createtypecourse &&
      // self.operativecourse_category &&
      // self.operativecourse_code &&
      // self.operativecourse_name &&
      // self.operativecourse_costprice &&
      // self.operativecourse_wagesdoctor &&
      // self.operativecourse_wagesemployee &&
      // self.operativecourse_amountcourse &&
      // self.operativecourse_amountday &&
      // self.operativecourse_detail) {
      //   setTimeout(() => {
      //     // swal('สำเร็จ', 'เพิ่มรายการหัตถการ/คอร์สสำเร็จ', 'success', {
      //     //   button: false,
      //     //   timer: 3000
      //     // }).then(() => {
      //     self.loaderSave = null
      //     self.loaderCancel = null
      //     // self.$router.go(-1)
      //     self.$router.push({ name: 'CreateAddDetailListOperativeAndCourseScreen', params: { createItem: objItems } })
      //     // })
      //   }, 200)
      // } else {
      //   swal('คำเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน', 'warning', {
      //     button: false,
      //     timer: 3000
      //   }).then(() => {
      //     self.loaderSave = null
      //     self.loaderCancel = null
      //   })
      // }
    }
  }
}
</script>

<style scoped>
@import '../../../styles/DrugsAndMedical_AddStock.css';
</style>
